import { AppErrorName, AppErrorPayload, AppErrors } from './errors';
import { AppEventName, AppEventPayload, AppEvents } from './contracts';

import { isDeepEqual } from '../util';

export type SubscriptionArg<Name extends AppEventName> = [
  Name,
  (e: AppEventPayload<Name>) => void
];

/**
 * AppEventTarget is a scoped event dispatcher
 * based on EventTarget
 *
 * It exists so that:
 * - Events can be emitted within a scope, rather than globally (i.e.: window)
 * - It's easier to do responsibility segregation. This is particularly important,
 *   to keep dependencies to a minimum in UI Components, service calls, etc.
 * - All application parts commit to the same contracts
 * - Isolation of application parts (log, Toast notifications, analytics, sentry, etc.) without
 *   publisher knowing about any of them
 */

export class AppEventTarget extends EventTarget {
  public dispatch<Name extends keyof AppEvents>(
    name: Name,
    ...[payload]: AppEvents[Name] extends undefined ? [] : [AppEvents[Name]]
  ): boolean {
    return this.dispatchEvent(
      new CustomEvent(name, {
        detail: payload,
        bubbles: true,
        cancelable: true,
        composed: false
      })
    );
  }

  public dispatchError<Name extends keyof AppErrors>(
    name: Name,
    ...[payload]: AppErrors[Name] extends undefined ? [] : [AppErrors[Name]]
  ): boolean {
    return this.dispatchEvent(
      new CustomEvent(name, {
        detail: {
          ...payload
        },
        bubbles: true,
        cancelable: true,
        composed: false
      })
    );
  }

  /**
   * Add a listener
   * @returns function to unsubscribe
   */
  public subscribe<Name extends AppEventName>(
    name: Name,
    listener: (e: AppEventPayload<Name>) => void
  ) {
    function handler(e: Event) {
      listener((e as CustomEvent).detail);
    }

    super.addEventListener(name, handler);
    return () => super.removeEventListener(name, handler);
  }

  /**
   * Add a listener
   * @returns function to unsubscribe
   */
  public subscribeToMany<Name extends AppEventName>(
    subs: Array<SubscriptionArg<Name>>
  ): Array<() => void> {
    const output = [];
    for (const sub of subs) {
      output.push(this.subscribe(sub[0], sub[1]));
    }

    return output;
  }

  /**
   * Add a listener
   * @returns function to unsubscribe
   */
  public subscribeMemo<Name extends AppEventName>(
    name: Name,
    listener: (e: AppEventPayload<Name>) => void
  ) {
    let previous: undefined | AppEventPayload<Name>;

    function handler(e: Event) {
      const payload = (e as CustomEvent).detail;
      if (!isDeepEqual(previous, payload)) {
        listener((e as CustomEvent).detail);
        previous = (e as CustomEvent).detail;
      }
    }

    super.addEventListener(name, handler);
    return () => super.removeEventListener(name, handler);
  }

  /**
   * Add a listener to error event
   * @returns function to unsubscribe
   */
  public subscribeError<Name extends AppErrorName>(
    name: Name,
    listener: (e: AppErrorPayload<Name>) => void
  ) {
    function handler(e: Event) {
      listener((e as CustomEvent).detail);
    }

    super.addEventListener(name, handler);
    return () => super.removeEventListener(name, handler);
  }

  public subscribeToAll<Name extends keyof AppEvents>(
    listener: (
      name: Name,
      ...[payload]: AppEvents[Name] extends undefined ? [] : [AppEvents[Name]]
    ) => void
  ) {
    const listeners: Array<[AppEventName, () => void]> = Object.values(
      AppEventName
    ).map(name => [
      name,
      this.subscribe(name, payload => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return listener(name as Name, payload);
      })
    ]);
    return () =>
      listeners.forEach(([name, l]) => super.removeEventListener(name, l));
  }

  public subscribeToAllErrors<Name extends AppErrorName>(
    listener: (
      name: Name,
      ...[payload]: AppErrorPayload<Name> extends undefined
        ? []
        : [AppErrors[Name]]
    ) => void
  ) {
    const listeners: Array<[string, () => void]> = Object.values(
      AppErrorName
    ).map(name => [
      name,
      this.subscribeError(name, (...data) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        listener(name as Name, ...(data as any))
      )
    ]);
    return () =>
      listeners.forEach(([name, l]) => super.removeEventListener(name, l));
  }
}
